// Typography
@layer components {
  .h1 {
    @apply text-4xl font-extrabold tracking-tighter;
    
    @screen md {
      @apply text-5xl;
    }
  }

  .h2 {
    @apply text-3xl font-extrabold tracking-tighter;
    
    @screen md {
      @apply text-4xl;
    }
  }

  .h3 {
    @apply text-3xl font-extrabold;
  }

  .h4 {
    @apply text-2xl font-extrabold tracking-tight;
  }

  // Buttons
  .btn,
  .btn-sm,
  .btn-xs {
    @apply font-medium text-sm inline-flex items-center justify-center border border-transparent rounded leading-5 shadow-sm transition duration-150 ease-in-out;
  }

  .btn {
    @apply px-2.5 py-2;
  }

  .btn-sm {
    @apply px-2 py-1;
  }

  .btn-xs {
    @apply px-2 py-0.5;
  }

  // Forms
  .form-input,
  .form-textarea,
  .form-multiselect,
  .form-select,
  .form-checkbox,
  .form-radio {
    @apply text-sm text-slate-800 bg-white border;
  }

  .form-input,
  .form-textarea,
  .form-multiselect,
  .form-select,
  .form-checkbox {
    @apply rounded;
  }

  .form-input,
  .form-textarea,
  .form-multiselect,
  .form-select {
    @apply leading-5 py-2 px-3 border-slate-200 hover:border-slate-300 focus:border-primary-300 shadow-sm;
  }

  .form-input,
  .form-textarea {
    @apply placeholder-slate-400;
  }

  .form-select {
    @apply pr-10;
  }

  .form-checkbox,
  .form-radio {
    @apply text-primary-500 border border-slate-300;
  }
}

// Utilities
@layer utilities {
  .no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
    
    &::-webkit-scrollbar {
      display: none;
    }
  }
}