:focus,
button:focus,
.btn:focus,
.btn-sm:focus,
.form-input:focus,
.form-textarea:focus,
.form-multiselect:focus,
.form-select:focus,
.form-checkbox:focus,
.form-radio:focus {
    @apply outline-none ring-2 ring-primary-500 ring-offset-2;
}

.form-input,
.form-textarea,
.form-multiselect,
.form-select,
.form-checkbox,
.form-radio {

    &:focus {
        box-shadow: none !important;
    }
}

@layer components {
  // Background color
  .bg-gradient {
    @apply bg-gradient-to-tr from-primary-500 to-primary-400;
  }

  // Buttons
  .btn-primary {
    @apply bg-primary-500 hover:bg-primary-600 text-white;
    
    &.active {
      @apply bg-primary-600;
    }
  }
  
  .btn-white {
    @apply bg-white hover:bg-slate-50 text-slate-600;
    
    &.active {
      @apply bg-slate-50;
    }
  }
}
