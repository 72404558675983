// Import base styles
@import '~flatpickr/dist/flatpickr.min.css';

// Variables
$calendar-padding: 1.5rem;
$day-size: 2.25rem;
$days-width: $day-size * 7;

// Animation
@keyframes fpFadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -0.5rem, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@layer components {
  .flatpickr-calendar {
    @apply rounded-lg shadow-lg border border-slate-200 left-1/2;
    border: inherit;
    padding: $calendar-padding;
    width: $days-width + $calendar-padding * 2;
    margin-left: -($days-width + $calendar-padding * 2) * 0.5;

    @screen lg {
      @apply left-auto right-0;
      margin-left: 0;
    }

    &.animate.open {
      animation: fpFadeInDown 200ms ease-out;
    }

    &.static {
      @apply absolute;
      top: calc(100% + 0.25rem);

      &.open {
        z-index: 20;
      }
    }
  }

  .flatpickr-days {
    width: $days-width;
  }

  .dayContainer {
    width: $days-width;
    min-width: $days-width;
    max-width: $days-width;
  }

  .flatpickr-day {
    @apply bg-slate-50 text-sm font-medium text-slate-600;
    max-width: $day-size;
    height: $day-size;
    line-height: $day-size;

    &,
    &.prevMonthDay,
    &.nextMonthDay {
      border: none;
      border-radius: 0;
    }

    &.flatpickr-disabled,
    &.flatpickr-disabled:hover,
    &.prevMonthDay,
    &.nextMonthDay,
    &.notAllowed,
    &.notAllowed.prevMonthDay,
    &.notAllowed.nextMonthDay {
      @apply text-slate-400;
    }

    &.selected,
    &.startRange,
    &.endRange,
    &.selected.inRange,
    &.startRange.inRange,
    &.endRange.inRange,
    &.selected:focus,
    &.startRange:focus,
    &.endRange:focus,
    &.selected:hover,
    &.startRange:hover,
    &.endRange:hover,
    &.selected.prevMonthDay,
    &.startRange.prevMonthDay,
    &.endRange.prevMonthDay,
    &.selected.nextMonthDay,
    &.startRange.nextMonthDay,
    &.endRange.nextMonthDay {
      @apply bg-primary-500 text-primary-50;
    }

    &.inRange,
    &.prevMonthDay.inRange,
    &.nextMonthDay.inRange,
    &.today.inRange,
    &.prevMonthDay.today.inRange,
    &.nextMonthDay.today.inRange,
    &:hover,
    &.prevMonthDay:hover,
    &.nextMonthDay:hover,
    &:focus,
    &.prevMonthDay:focus,
    &.nextMonthDay:focus,
    &.today:hover,
    &.today:focus {
      @apply bg-primary-400 text-primary-50;
    }
  }

  .flatpickr-months {
    @apply items-center;
    margin-top: -0.5rem;
    margin-bottom: 0.375rem;

    .flatpickr-prev-month,
    .flatpickr-next-month {
      @apply text-slate-600;
      position: static;
      height: auto;

      svg {
        width: 0.4375rem;
        height: 0.6875rem;
      }

      &:hover {
        @apply text-slate-400;
        
        svg {
          fill: currentColor;
        }
      }
    }

    .flatpickr-prev-month {
      margin-left: -0.625rem;
    }

    .flatpickr-next-month {
      margin-right: -0.625rem;
    }

    .flatpickr-month {
      @apply text-slate-800;
      height: auto;
      line-height: inherit;
    }
  }

  .flatpickr-current-month {
    @apply text-sm font-medium;
    position: static;
    height: auto;
    width: auto;
    left: auto;
    padding: 0;

    span.cur-month {
      @apply font-medium m-0;

      &:hover {
        background: none;
      }
    }

    input.cur-year {
      font-weight: inherit;
      box-shadow: none !important;
    }
  }

  .numInputWrapper {
    &:hover {
      background: none;
    }

    span {
      display: none;
    }
  }

  span.flatpickr-weekday {
    @apply text-slate-400 font-medium text-xs;
  }

  .flatpickr-calendar.arrowTop {
    &::before,
    &::after {
      display: none;
    }
  }
}