@use 'sass:math';
@use 'sass:color';
@use './additional-styles/utility-patterns';
@use './additional-styles/range-slider';
@use './additional-styles/toggle-switch';
@use './additional-styles/flatpickr';
@use './additional-styles/theme';

// Fonts
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=fallback');

// Tailwind
@tailwind base;
@tailwind components;
@tailwind utilities;

// Base styles
@layer base {
  html {
    @apply scroll-smooth;
  }

  body {
    @apply font-inter antialiased bg-slate-50 text-slate-600;
  }
}

// Utility classes
@layer utilities {
  .body-bg {
    @apply bg-gradient-to-br from-primary-600 to-white;
  }
}

// Component styles
@layer components {
  .btn-primary {
    @apply inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-lg font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 transition-colors duration-150;
  }

  .btn-secondary {
    @apply inline-flex items-center justify-center px-4 py-2 border border-slate-300 rounded-lg font-medium text-slate-700 bg-white hover:bg-slate-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 transition-colors duration-150;
  }

  .input-field {
    @apply block w-full px-3 py-2 border border-slate-300 rounded-lg shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm transition-colors duration-150;
  }
}

